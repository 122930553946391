<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap17GoModules"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 17: Go modules" image-name="modules.jpg" image-alt="Go modules"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a Go module?</p></li>
<li><p>What are a dependency and a dependency graph?</p></li>
<li><p>The Go approach to version selection.</p></li>
<li><p>How to use Semantic Versioning.</p></li>
<li><p>How to perform basic go Modules operations</p>
<ul>
<li>upgrade all, upgrade one, downgrade one.</li>
</ul></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h2 data-number="1.1"><span class="header-section-number">1.1</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Dependency</p></li>
<li><p>Version</p></li>
<li><p>Semantic Versioning (SemVer)</p></li>
<li><p>Module</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Modules have been introduced with version 1.11 of Go. Gophers can split their developments into separate code units that can be reused across other projects.</p>
<div id="dependency" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Dependency <a href="#dependency"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Developers reuse code that their peers developed to :</p>
<ul>
<li><p>Reduce the development time</p>
<ul>
<li><p>Somebody might have already developed some basic functionalities team. Why taking time to develop it yourself?</p></li>
<li><p>What is your added value?</p></li>
</ul></li>
<li><p>Reduce the maintenance cost. <b-link class="citation" data-cites="hejderup2018software" href="#hejderup2018software" >[@hejderup2018software]</b-link>.</p>
<ul>
<li><p>The code that your team write needs to be maintained (ie. solving bugs, fixing vulnerabilities ...)</p></li>
<li><p>The code written by a dynamic community might be efficiently maintained.</p>
<ul>
<li><p>An important criterion when choosing a dependency is to check the community’s dynamism around the project.</p></li>
<li><p>A trendy project with many contributions might be “safer” than another maintained by a couple of developers.</p></li>
</ul></li>
</ul></li>
</ul>
<p>A program may rely on ten other programs or libraries. The list of programs and libraries that a program uses is called its dependencies. We say that a program is <strong>dependent</strong> on another piece of software.</p>
<p>Go gives you the ability to use code written by others with Go modules easily.</p>
<div id="definition-of-a-go-module" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Definition of a Go module <a href="#definition-of-a-go-module"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A module is a group of packages (or a single package) that are (is) versioned. This group of go files forms together a <strong>module</strong>. Modules can be dependant on other modules.</p>
<ul>
<li><p>A module is identified by a string which is called the “module path”.</p></li>
<li><p>The requirements (if any) of the module are listed in a specific file.</p>
<ul>
<li><p>Go will use this file for each installation to build the module.</p></li>
<li><p>The file is named <strong>go.mod</strong>.</p></li>
</ul></li>
<li><p>Go will also generate a file named go.sum. We will see later what it is.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/3_modules.png')" alt="A module is a set of packages with a go.mod file and a go.sum file"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A module is a set of packages with a go.mod file and a go.sum file</figcaption>
</figure>
<div id="the-go.mod-file" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> The go.mod file <a href="#the-go.mod-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The go.mod file has the following structure</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/maximilienandile/myAwesomeModule

go 1.15

require (
    github.com/PuerkitoBio/goquery v1.6.1
    github.com/aws/aws-sdk-go v1.36.31
)</code></pre>
<ul>
<li><p>The first line gives the <strong>module path</strong> (it can be either a local path or a URI to a repository hosted on a version control system).</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">module</code></span> is a reserved keyword. In the example, the module is hosted on gitlab.com on my account. The project is named myAwesomeModule.</p></li>
<li><p>The second line will give the version of Go used by the developer</p></li>
<li><p>Then another section define the dependencies that are used by the module :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >require (
    DEPENDENCY_1_PATH VERSION_OF_DEPENDENCY_1
    DEPENDENCY_2_PATH VERSION_OF_DEPENDENCY_2
   //...
)</code></pre>
<p>First, the module path and then the desired version :</p>
<pre v-highlightjs><code class="go" v-pre >github.com/PuerkitoBio/goquery v1.6.1</code></pre>
<p>We are loading the module github.com/PuerkitoBio/goquery hosted on GitHub And we require version v1.6.1.</p>
<div id="initialization-with-the-go-command-line-on-an-empty-project" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> Initialization with the go command line (on an empty project) <a href="#initialization-with-the-go-command-line-on-an-empty-project"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The go.mod file can be created automatically with the go command line :</p>
<pre v-highlightjs><code class="go" v-pre >$ go mod init my/module/path</code></pre>
<p>This will generate the following file :</p>
<pre v-highlightjs><code class="go" v-pre >module my/module/path

go 1.15</code></pre>
<p>For the moment, our go.mod file does not contain any dependency.</p>
<p>We will list dependencies with the <strong>require</strong> keyword.</p>
<p>For instance :</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/maximilienandile/myawesomemodule

require (
    github.com/go-redis/redis/v8 v8.4.10
)</code></pre>
<p>It means that the module <u>gitlab.com/maximilienandile/myawesomemodule</u> require the module <u>github.com/go-redis/redis/v8</u> at version v8.4.10</p>
<div id="initialization-on-an-existing-project" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> Initialization on an existing project <a href="#initialization-on-an-existing-project"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can generate the go.mod file on an existing project with the go command line. Let’s take an example.</p>
<p>We can create a file (main.go) that will import code from the repository gitlab.com/loir42/gomodule :</p>
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;gitlab.com/loir42/gomodule&quot;

func main() {
   gomodule.WhatTimeIsIt()
}</code></pre>
<p>The module we have imported has just one exposed function: <span v-highlightjs><code class="go" v-pre style="display: inline">WhatTimeIsIt</code></span> (in its package <span v-highlightjs><code class="go" v-pre style="display: inline">timer</code></span>) :</p>
<figure>
<b-img :src="require('@/assets/images/tree_view_package_timer.png')" alt="Tree view Package timer"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Tree view Package timer</figcaption>
</figure>
<p>Here is the file content of timer.go :</p>
<pre v-highlightjs><code class="go" v-pre >package gomodule

import &quot;time&quot;

func WhatTimeIsIt() string {
   return time.Now().Format(time.RFC3339)
}</code></pre>
<p>If you run the command <strong>go mod init</strong> a go.mod file will be created. It will not list your dependency yet:</p>
<pre v-highlightjs><code class="go" v-pre >module go_book/modules/app</code></pre>
<p>Then if you build your project by typing on your terminal <strong>go build</strong> the go.mod file will be modified, and the dependency is added to the require section :</p>
<pre v-highlightjs><code class="go" v-pre >module go_book/modules/app

require gitlab.com/loir402/gomodule v0.0.1</code></pre>
<p>We did not specify anything for the required module version. Consequently, the build tool has retrieved the most recent tag from the remote repository.</p>
<div id="exclusion" class="anchor"></div>
<h2 data-number="5.3"><span class="header-section-number">5.3</span> Exclusion <a href="#exclusion"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In the go.mod, you can explicitly exclude a version from your build :</p>
<pre v-highlightjs><code class="go" v-pre >exclude gitlab.com/loir402/bluesodium v2.0.1</code></pre>
<p>Note that you can exclude more than one module-version pair :</p>
<pre v-highlightjs><code class="go" v-pre >exclude (
   gitlab.com/loir402/bluesodium v2.0.1
   gitlab.com/loir402/bluesodium v2.0.0
)</code></pre>
<div id="replacement" class="anchor"></div>
<h2 data-number="5.4"><span class="header-section-number">5.4</span> Replacement <a href="#replacement"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>It is possible to replace the code of a module with the code of another module with the directive “replace” :</p>
<pre v-highlightjs><code class="go" v-pre >replace (
   gitlab.com/loir402/bluesodium v2.0.1 =&gt; gitlab.com/loir402/bluesodium2 v1.0.0
   gitlab.com/loir402/corge =&gt; ./corgeforked
)</code></pre>
<p>The replaced version is at the left of the arrow; the replacement is at the right.</p>
<p>The replacement module can be :</p>
<ul>
<li><p>Stored on a <strong>code sharing website</strong> (ex: Github, GitLab .…)</p></li>
<li><p>Stored <strong>locally</strong></p></li>
</ul>
<p><strong>Some important notes :</strong></p>
<ul>
<li><p>The replacement module should have <strong><u>the same module directive</u></strong> (the first line of the go.mod file).</p></li>
<li><p><strong>Should the replacement specify a version?</strong> It depends on the <u>location</u> of the replacement :</p>
<ul>
<li><p>Distant (Github, Gitlab) : required</p></li>
<li><p>Local: not required</p></li>
</ul></li>
<li><p><strong>A specific version</strong> of a module can be replaced OR <strong>all versions</strong> can be replaced.</p>
<pre v-highlightjs><code class="go" v-pre >gitlab.com/loir402/corge =&gt; ./corgeforked</code></pre>
<p>will replace <strong>all</strong> versions of gitlab.com/loir402/corge by a local version</p>
<pre v-highlightjs><code class="go" v-pre >gitlab.com/loir402/corge v0.1.0 =&gt; ./corgeforked</code></pre>
<p>will replace <strong>only</strong> version v0.1.0 by the local code.</p></li>
</ul>
<div id="vocabulary-api" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Vocabulary: API <a href="#vocabulary-api"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>API stands for <strong>A</strong>pplication <strong>P</strong>rogramming <strong>I</strong>nterface. The most important letter is I. An API is an <strong>interface</strong>.</p>
<p>An interface is like a frontier between two things, a “shared boundary across which two or more separate components of a computer system exchange information” (Wikipedia).</p>
<p>An interface in computer science is a way for two different things to communicate. So what is an Application Programming Interface? It’s a set of constructs (constants, variables, functions ...) that are exposed to interact with a piece of software.</p>
<p>With this definition we can say that the go package <span v-highlightjs><code class="go" v-pre style="display: inline">fmt</code></span> exposes an API to the go programmer to interact with it. Its API represents the set of functions that you can use for instance <span v-highlightjs><code class="go" v-pre style="display: inline">Println</code></span><strong>. It also covers the set of exported identifiers of the package (constants, variables, types).</strong></p>
<p>We can also say that the Linux kernel is exposing an <strong>API</strong> to interact with him, for instance, the function <span v-highlightjs><code class="go" v-pre style="display: inline">bitmap_find_free_region</code></span> will tell the kernel to find a contiguous, aligned memory region.</p>
<p>=&gt; Go Modules expose an API that is composed of all <strong>exported</strong> identifiers of the package(s) that the module is composed of.</p>
<div id="vocabulary-version" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Vocabulary: Version <a href="#vocabulary-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Programs naturally evolve:</p>
<ul>
<li><p>Additional functionalities are added (or removed)</p></li>
<li><p>Bugs are fixed (or created)</p></li>
<li><p>Performance is improved (or decreased)</p></li>
</ul>
<p>Developers will add revisions to the original program, making it different (for the better or, the worse). Sometimes, a module’s API will evolve: for instance, a previously exported function is deleted.</p>
<p>Programs relying on these particular functions will break (because it’s no longer exported).</p>
<p>Developers need to have a way to designate precise versions of a piece of code."<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a></p>
<p>A version is an <strong>unique identifier</strong> that designate a program at <strong>a specific revision and point in time</strong>.</p>
<p>This unique identifier is generated based on a set of well-known and shared rules. The set of rules and the format of the identifier is called a “versioning scheme”. There are several schemes available. Go use Semantic Versioning. We will detail it in the next section.</p>
<div id="vocabulary-tag-revision-prerelease-release" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Vocabulary: tag, revision, prerelease, release <a href="#vocabulary-tag-revision-prerelease-release"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p><strong>Tagged</strong> : it means that a “<strong>tag</strong>” has been created with a Version Control system.</p></li>
<li><p>A tag is a <strong>string (</strong>it’s name <strong>)</strong> that designate a specific revision of a project maintained by a Version Control System.</p>
<ul>
<li><p>Ex : “v1.0.1” is a tag</p></li>
<li><p>The string “v1.0.1” is the tag name</p></li>
<li><p>It designates the code of a repository at a very specific revision.</p></li>
<li><p>Note that a tag can be <strong>any string</strong>.</p></li>
<li><p><strong>"GoIsMyFavLanguage</strong>" is a valid tag.</p></li>
</ul></li>
<li><p>A tag can designate a <strong>released version</strong> or a <strong>pre-released version</strong> of the software</p>
<ul>
<li><p>A pre-release version (or release candidate) is considered to be ready. It is made available for last minutes tests.</p></li>
<li><p>Yet, it is not considered as a stable release (or just “release”).</p></li>
<li><p>Pre-release versions have specific tags with appended characters :</p>
<ul>
<li><p>For instance : 1.0.0-alpha, 1.0.0-alpha.1</p></li>
<li><p>Each project has different conventions about that</p></li>
</ul></li>
</ul></li>
<li><p>An <strong>untagged version</strong> is a specific state of the program at a given time.</p>
<ul>
<li><p>In the Git VCS, it’s a “<strong>commit</strong>”</p></li>
<li><p>The Git VCS will identify each commit with a <strong>SHA1</strong> checksum (ex : 409d7a2e37605dc5838794cebace764ea4022388)</p></li>
</ul></li>
</ul>
<div id="semantic-versioning" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Semantic Versioning <a href="#semantic-versioning"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Semantic Versioning<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a> is a norm that Tom Preston-Werner wrote. Tom<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a>. This specification defines the way version numbers are formed. It is widely used in the developer community.</p>
<p>In this section, I will detail some important requirements of Semantic Versioning :</p>
<div id="version-number-format" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Version number format <a href="#version-number-format"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li>A version number must have the following format : <strong>X.Y.Z</strong></li>
</ul>
<p>Where :</p>
<div class="list">
<p>the <strong>major</strong> version</p>
<p>the <strong>minor</strong> version</p>
<p>the <strong>patch</strong> version (bug fix)</p>
</div>
<p>How does it work? X, Y, and Z are positive numbers (without leading zeroes). Those numbers are incremented following a specific norm.</p>
<ul>
<li><p>When you create new features that <strong>breaks the existing API</strong> of your software, you increment the major version number.</p>
<ul>
<li>old version : 1.0.0 / new version : 2.0.0</li>
</ul></li>
<li><p>When you create new features or make performance improvement that <strong>do not break the existing API</strong> you increment the minor version number.</p>
<ul>
<li>old version : 1.0.0 / new version 1.1.0</li>
</ul></li>
<li><p>When you fix a bug in your code, you just increment the patch version</p>
<ul>
<li>old version : 1.0.0 / new version 1.0.1</li>
</ul></li>
</ul>
<p>When you create a major version, you set to zero the minor and the patch version number. When you release a new feature, you set to zero the patch version.</p>
<div id="major-version-0" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> Major version 0 <a href="#major-version-0"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>During the beginning phase of a project, you know that things will change; your public API might not be the same later. During this phase of development, you can still create a version. The major number is then fixed to 0 but minor and patch version number can still be incremented. Other developers will know when they integrate your code into their software that your software is not considered as <strong>stable (meaning that the public API might change without notifications)</strong></p>
<div id="a-tool-to-ensure-stability" class="anchor"></div>
<h2 data-number="9.3"><span class="header-section-number">9.3</span> A tool to ensure stability <a href="#a-tool-to-ensure-stability"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you increment your major version number, you also communicate to others that you have developed changes that are not compatible with the previous major version.</p>
<p>Let’s take an example of an API change. A module called <strong>gomodule</strong> expose a function to the outside world called WhatTimeIsIt :</p>
<pre v-highlightjs><code class="go" v-pre >package gomodule

import &quot;time&quot;

func WhatTimeIsIt() string {
    return time.Now().Format(time.RFC3339)
}</code></pre>
<p>The developer has released version 0.0.1 of this code. Later the same developer has received many complaints from others about the format of time. Fellow developers want to be able to specify a format for the time. RFC3339 is cool, but they want to choose. A new release is prepared :</p>
<pre v-highlightjs><code class="go" v-pre >package gomodule

import &quot;time&quot;

func WhatTimeIsIt(format string) string {
    return time.Now().Format(format)
}</code></pre>
<p>The <strong>signature of the function</strong> has changed. As a consequence, code that uses this function will break if they import this new version. That’s why the developer has to create a new <strong>major</strong> version : 1.0.0.</p>
<div id="delete-a-version" class="anchor"></div>
<h2 data-number="9.4"><span class="header-section-number">9.4</span> Delete a version? <a href="#delete-a-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Once the version is created, we must not change the software. You cannot tag your software to a specific version, then delete the tag and recreate it with the same version number.</p>
<div id="dependency-graph" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Dependency Graph <a href="#dependency-graph"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Handling dependencies is a complicated task, and many approaches exist. But first, I want to define the concept of a dependency graph.</p>
<p>The <strong>dependency graph</strong> holds in a structured representation the dependencies and the sub-dependencies that are required for an application to work <b-link class="citation" data-cites="li2003managing" href="#li2003managing" >[@li2003managing]</b-link>.</p>
<figure>
<b-img :src="require('@/assets/images/dep_graph.png')" alt="Dependency Graph[fig:Dependency-Graph]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Dependency Graph<span id="fig:Dependency-Graph" label="fig:Dependency-Graph">[fig:Dependency-Graph]</span></figcaption>
</figure>
<p>The figure <a href="#fig:Dependency-Graph" data-reference-type="ref" data-reference="fig:Dependency-Graph">1</a> reprensents the dependency graph of the myApp application. Each package is represented by a <strong>node</strong> (a circle). Nodes are also called vertices or leaves. For instance <strong>myApp</strong> is a node.</p>
<p>An edge is a link between two nodes. Edges are represented with straight lines. In a dependency graph, edges have a direction represented by an arrow. Direct edges represent a dependency.</p>
<ul>
<li>myApp directly depends on two packages: foo and bar</li>
</ul>
<p>We say that foo and bar are descendants of myApp.</p>
<ul>
<li><p>The package foo does not depend on anything. <strong>Foo</strong> has no descendant.</p></li>
<li><p><strong>Bar</strong> depends on two other packages <strong>baz</strong> and <strong>qux</strong>.</p></li>
<li><p><strong>Qux</strong> requires corge to work</p></li>
<li><p><strong>Baz</strong> requires also <strong>corge</strong> to work</p></li>
</ul>
<div id="dependency-solving" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Dependency solving <a href="#dependency-solving"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The dependency graph represents the dependencies required, but at the end, we need a list of dependencies to install our package. To create this final list, we need to respect the graph’s requirements. Dependency solving is the process of finding this final list.</p>
<p>In our previous example, the final list will be :</p>
<ul>
<li><p>Foo</p></li>
<li><p>Bar</p></li>
<li><p>Baz</p></li>
<li><p>Qux</p></li>
<li><p>Corge</p></li>
</ul>
<p>Note that we only need to include Corge once, even if it is required two times (by Baz and Qux).</p>
<div id="the-problem-of-version-selection" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> The problem of version selection <a href="#the-problem-of-version-selection"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the previous example, we did not consider any version number. In this section, we will add version numbers.</p>
<figure>
<b-img :src="require('@/assets/images/dep_graph_version.png')" alt="Dependency Graph With versions[fig:Dependency-Graph-version]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Dependency Graph With versions<span id="fig:Dependency-Graph-version" label="fig:Dependency-Graph-version">[fig:Dependency-Graph-version]</span></figcaption>
</figure>
<p>In the figure <a href="#fig:Dependency-Graph-version" data-reference-type="ref" data-reference="fig:Dependency-Graph-version">2</a> we have represented the version numbers :</p>
<ul>
<li>myApp v1.0.0 depends on foo v1.2.0 and on bar v1.3.8 ...</li>
</ul>
<p>We see that the node corge has been split into two new nodes in the graph. That’s because <strong>baz and qux depends on corge, but the two packages do not depend on exactly the same version</strong>.</p>
<p>Which version should we add to our dependency list?</p>
<ul>
<li><p>The v2.0.0 or the version 2.7.0 ?</p></li>
<li><p>Both?</p></li>
<li><p>Just the more recent one, which is in this case v2.7.0?</p></li>
</ul>
<p>We will see how Go handle this choice in the next section.</p>
<div id="the-go-approach-minimal-version-selection" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> The Go approach: Minimal Version Selection <a href="#the-go-approach-minimal-version-selection"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Minimal Version Selection (or MVS) is a set of algorithms<a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a> used under the hood by the go command line to :</p>
<ul>
<li><p>Generate the go.mod file (and the go.sum), that lists all dependencies used by a project.</p></li>
<li><p>Update one or several dependencies</p></li>
<li><p>Downgrade one or several dependencies</p></li>
</ul>
<p>MVS has been theorized by Russ Cox (one of the language developers). Russ wanted to create a system that was “Understandable. Predictable. Boring”<b-link class="citation" data-cites="minimal-version-cox" href="#minimal-version-cox" >[@minimal-version-cox]</b-link>.</p>
<p>In this section, we will detail how it works by focusing on the main operations we will do every day:</p>
<div id="the-two-base-rules" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> The two base rules <a href="#the-two-base-rules"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Each module will give a <u>list of module requirements</u></p>
<ul>
<li><p>Modules are identified by a <strong><u>module path</u></strong></p></li>
<li><p>Each module required to specify a <strong><u>minimal compatible version</u></strong></p></li>
<li><p>This list is in the <strong>go.mod</strong> file</p></li>
</ul></li>
<li><p>Each module should follow the “<strong><u>import compatibility rule</u></strong>”.</p>
<ul>
<li>Modules with the <strong>same module path</strong> should be <strong>backward compatible</strong>.</li>
</ul></li>
</ul>
<div id="how-to-add-a-new-dependency" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> How to add a new dependency <a href="#how-to-add-a-new-dependency"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you add a new dependency to your project (by calling <strong>go get</strong>) it will :</p>
<ul>
<li><p>Download the module required</p></li>
<li><p>Add the module required to your <strong>go.mod</strong> file</p></li>
</ul>
<div id="which-version-is-selected" class="anchor"></div>
<h4 data-number="13.2.0.1"><span class="header-section-number">13.2.0.1</span> Which version is selected? <a href="#which-version-is-selected"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Go will choose <strong><u>by order of preference</u></strong></p>
<ol type="1">
<li><p>The latest tagged stable release</p></li>
<li><p>The lastest tagged pre-release</p></li>
<li><p>The latest untagged version (latest known commit, also called latest <strong>pseudo-version</strong>)</p></li>
</ol>
<div id="construction-of-the-build-list" class="anchor"></div>
<h2 data-number="13.3"><span class="header-section-number">13.3</span> Construction of the build list <a href="#construction-of-the-build-list"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>The build list is the list of modules necessary to build a Go Program.</p></li>
<li><p>Each item of this list is composed of two things</p>
<ul>
<li><p>A module path identifies a module</p></li>
<li><p>A revision identifier (which can be a tag or a commit id)</p></li>
</ul></li>
</ul>
<p>The steps required to create the build list for a given module are<a href="#fn5" class="footnote-ref" id="fnref5" role="doc-noteref"><sup>5</sup></a> :</p>
<ol type="1">
<li><p>Initialize an empty list L</p></li>
<li><p>Take the list of modules required for the current module (go.mod)</p></li>
<li><p>For each module required</p>
<ol type="1">
<li><p>Get the list of modules required by this module (go.mod)</p></li>
<li><p>append those elements to the list L</p></li>
<li><p>repeat the operation for elements appended to the list</p></li>
</ol></li>
<li><p>In the end, the list may contain multiple entries for the same module path.</p>
<ol type="1">
<li>If so, for each module path, keep the newest version.</li>
</ol></li>
</ol>
<p>To display the final build list of a module, you can type the command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go list -m all</code></pre>
<p>In the following figure, you can see a mindmap that represents requirements for a module that requires only gitlab.com/loir402/bluesodium/v2 at version v2.1.1.</p>
<ul>
<li><p>We begin by listing the requirements of bluesodium at the requested version</p></li>
<li><p>It requires goquery</p>
<ul>
<li><p>Which requires itself cascadia</p></li>
<li><p>And net</p>
<ul>
<li><p>, which requires crypto, sys, and text.</p></li>
<li><p>.…</p></li>
</ul></li>
</ul></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/modules_requirement_mindmap.png')" alt="Example of requirements for an application requiring bluesodium"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Example of requirements for an application requiring bluesodium</figcaption>
</figure>
<p>In the end, we have the following list :</p>
<ul>
<li><p>github.com/PuerkitoBio/goquery v1.6.1</p></li>
<li><p>github.com/andybalholm/cascadia v1.1.0</p></li>
<li><p>gitlab.com/loir402/bluesodium/v2 v2.1.1</p></li>
<li><p>golang.org/x/crypto v0.0.0-20190308221718-c2843e01d9a2</p></li>
<li><p><strong>golang.org/x/net v0.0.0-20200202094626-16171245cfb2</strong></p></li>
<li><p>golang.org/x/sys v0.0.0-20190215142949-d0b11bdaac8a</p></li>
<li><p>golang.org/x/text v0.3.0</p></li>
<li><p><strong>golang.org/x/net v0.0.0-20180218175443-cbe0f9307d01</strong></p></li>
</ul>
<p>The net module is required twice (in bold) :</p>
<ul>
<li><p>golang.org/x/net v0.0.0-20200202094626-16171245cfb2</p></li>
<li><p>golang.org/x/net v0.0.0-20180218175443-cbe0f9307d01</p></li>
</ul>
<p>The version <strong>golang.org/x/net v0.0.0-20200202094626-16171245cfb2</strong> is prefered because it’s the <strong>most recent</strong>.</p>
<div id="how-to-upgrade-a-dependency-to-the-latest-minor-or-patch" class="anchor"></div>
<h2 data-number="13.4"><span class="header-section-number">13.4</span> How to upgrade a dependency to the latest minor or patch <a href="#how-to-upgrade-a-dependency-to-the-latest-minor-or-patch"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The first time you add a dependency to your project, Go will download a specific revision :</p>
<ul>
<li><p>A tagged version or,</p></li>
<li><p>A tagged prerelease or,</p></li>
<li><p>A specific commit</p></li>
</ul>
<p>When you want to upgrade a dependency to its next version, you can type :</p>
<pre v-highlightjs><code class="go" v-pre >go get -u gitlab.com/loir402/bluesodium</code></pre>
<p>The</p>
<pre data-float=""><span v-highlightjs><code class="go" v-pre style="display: inline">-u</code></span></pre>
<p>flag will download the newer <strong>minor</strong> or <strong>patch</strong><a href="#fn6" class="footnote-ref" id="fnref6" role="doc-noteref"><sup>6</sup></a></p>
<div id="upgrade-a-dependency-to-a-new-major-version" class="anchor"></div>
<h2 data-number="13.5"><span class="header-section-number">13.5</span> Upgrade a dependency to a new major version <a href="#upgrade-a-dependency-to-a-new-major-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Your project uses the version v1.0.1 of the module <span v-highlightjs><code class="go" v-pre style="display: inline">gitlab.com/loir402/bluesodium</code></span>.</p>
<p>The maintainer released a new <strong>major</strong> version: tags beginning by <strong>v2</strong> will appear on the repository :</p>
<figure>
<b-img :src="require('@/assets/images/gitlab_new_tags.png')" alt="Screenshot of tags on GitLab for the module gitlab.com/loir402/bluesodium"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Screenshot of tags on GitLab for the module gitlab.com/loir402/bluesodium</figcaption>
</figure>
<p>A major version is released on a module you use in your program. When you attempt to upgrade it with the command :</p>
<pre v-highlightjs><code class="go" v-pre >go get -u gitlab.com/loir402/bluesodium</code></pre>
<p>It will not download the newest major version (v2.0.1). Why?</p>
<ul>
<li><p>Version 2 of the module have a different path</p></li>
<li><p>This is the direct application of the “<strong>import compatibility</strong>” rule:</p>
<ul>
<li><p>The rule is: “Modules with the <strong>same module path</strong> should be <strong>backward compatible</strong>.”</p></li>
<li><p>Version 2 of a module introduces breaking changes. Those changes will impact the users of the previous versions.</p></li>
<li><p>Therefore, it should have a <strong>different</strong> module path</p></li>
</ul></li>
</ul>
<p>Let’s take a look at the go.mod file of the dependency :</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/loir402/bluesodium/v2

go 1.15</code></pre>
<p>The module path has changed; it’s no longer <span v-highlightjs><code class="go" v-pre style="display: inline">gitlab.com/loir402/bluesodium</code></span> but it’s <span v-highlightjs><code class="go" v-pre style="display: inline">gitlab.com/loir402/bluesodium/v2</code></span>.</p>
<p>When a module switch from v0 or v1 to v2, it should modify its path to comply with the <u>import compatibility rule</u>.</p>
<p>To specifically require the major version 2, you need to launch the command. :</p>
<pre v-highlightjs><code class="go" v-pre >go get -u gitlab.com/loir402/bluesodium/v2</code></pre>
<div id="upgrade-all-modules-to-the-latest-version" class="anchor"></div>
<h2 data-number="13.6"><span class="header-section-number">13.6</span> Upgrade all modules to the latest version <a href="#upgrade-all-modules-to-the-latest-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li>Each requirement is read “as if it required the latest module version”<b-link class="citation" data-cites="minimal-version-cox" href="#minimal-version-cox" >[@minimal-version-cox]</b-link> when the build list is constructed.</li>
</ul>
<p>Because of the import compatibility rule, no breaking changes should be introduced (this operation only requests new patches and minor versions).</p>
<p>If new versions are found then, the build list is modified AND also the go.mod file.</p>
<p>Concretely to do so, you will type the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get -u ./...
go: github.com/andybalholm/cascadia upgrade =&gt; v1.2.0
go: golang.org/x/net upgrade =&gt; v0.0.0-20210119194325-5f4716e94777</code></pre>
<p>The command will output the upgraded dependencies (here cascadia and net). Let’s take a look at the go.mod</p>
<pre v-highlightjs><code class="go" v-pre >module thisIsATest

go 1.15

require (
    github.com/andybalholm/cascadia v1.2.0 // indirect
    gitlab.com/loir402/bluesodium/v2 v2.1.1
    golang.org/x/net v0.0.0-20210119194325-5f4716e94777 // indirect
)</code></pre>
<p>Here you can see that the upgraded modules have been added to the go.mod and a comment “<strong>indirect</strong>” has been added. Why? Those lines are added to ensure that we use those specific upgraded versions when constructing the build list. Otherwise, the build list will stay the same.</p>
<div id="upgrade-one-module-to-a-specific-newer-version" class="anchor"></div>
<h2 data-number="13.7"><span class="header-section-number">13.7</span> Upgrade one module to a specific newer version <a href="#upgrade-one-module-to-a-specific-newer-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In this case, we want to update only one particular module to its newest version. The algorithm used is the following</p>
<ul>
<li><p>A first build list is constructed as if no upgrades are made.</p></li>
<li><p>A second one is constructed with the requested upgrade.</p></li>
<li><p>Then the two lists are merged.</p></li>
<li><p>If a module is listed twice in the list, then <strong>the newest version is selected</strong>.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/upgrade_one_module_to_specific_newer.png')" alt="Upgrade One module to a specific new version"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Upgrade One module to a specific new version</figcaption>
</figure>
<div id="downgrade-one-module-to-a-specific-lower-version" class="anchor"></div>
<h2 data-number="13.8"><span class="header-section-number">13.8</span> Downgrade one module to a specific lower version <a href="#downgrade-one-module-to-a-specific-lower-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Downgrading a dependency is a common action. It might be necessary if one of the dependency used :</p>
<ul>
<li><p>Introduce a bug</p></li>
<li><p>Introduce a security vulnerability</p></li>
<li><p>Reduce the application performance</p></li>
<li><p>and many other reasons</p></li>
</ul>
<p>In that case, the developer needs to be able to use a previous version of the dependency.</p>
<p>Let’s say that we used module E at version v1.1.0 and we want to downgrade the version of E to v1.0.0. Go will take each requirement of the application :</p>
<ul>
<li><p>The build list is constructed for that particular requirement</p></li>
<li><p>If the build list contains a forbidden version of E (ie. E at version v1.1.0 or above)</p>
<ul>
<li><p>Then the version of that requirement is downgraded</p></li>
<li><p>If the downgraded version still contains E at version v1.1.0 (or above) it is downgraded at the previous version</p></li>
<li><p>The task is repeated until the forbidden versions are not in the build list.</p></li>
</ul></li>
</ul>
<p>This operation will potentially remove requirements that are no longer needed.</p>
<div id="impact-of-module-exclusion" class="anchor"></div>
<h2 data-number="13.9"><span class="header-section-number">13.9</span> Impact of module exclusion <a href="#impact-of-module-exclusion"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In the previous section, we have seen that the go.mod file can exclude one module at a particular version (with the “exclude” directive).</p>
<p>In that case, Go will find the next higher version (not excluded) of the module. It will search in the list of :</p>
<ul>
<li><p>Releases</p></li>
<li><p>Prereleases</p></li>
</ul>
<p>Note that pseudo-versions (commits) are excluded from the selection process.</p>
<div id="vocabulary-checksum" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Vocabulary: checksum <a href="#vocabulary-checksum"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>This is a set of characters that are generated by an algorithm (hashing algorithm) that takes as input data (strings, files ...).</p></li>
<li><p>One of the purposes of a checksum is to check the integrity of something quickly that was transmitted over a network.</p>
<ul>
<li><p>The transmission of data over a network is not 100% safe, and sometimes you can get a corrupted file (either due to the network or due to a hacking attempt).</p></li>
<li><p>If you compare the checksum that the author of the file generated with the one you generate yourself, you can be almost sure that you have the same file.</p></li>
<li><p>I say “almost” because the hashing algorithm you use can be too weak and generate identical checksum from different files. For instance, the MD5 algorithm can generate the same checksum for two very different files. We call this <strong>hash collisions</strong>.</p></li>
</ul></li>
</ul>
<div id="vocabulary-hash-vs-encoding-vs-encryption" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Vocabulary: hash vs encoding vs encryption <a href="#vocabulary-hash-vs-encoding-vs-encryption"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The hash is the string of characters produced by the hashing algorithm. Hashing is <strong>different</strong> from encryption and encoding.</p>
<ul>
<li><p>Hash</p>
<ul>
<li><p>A hashing algorithm outputs hashes of the same size.</p></li>
<li><p>It takes as input a piece of data (file, string, zip, ...)</p></li>
<li><p>Theoretically, you CANNOT go back to the hash’s original input.</p></li>
</ul></li>
<li><p>Encode</p>
<ul>
<li><p>This is the process of converting a piece of data from a format to another format.</p></li>
<li><p>From the output, we CAN go back to the input.</p></li>
</ul></li>
<li><p>Encrypt</p>
<ul>
<li><p>This is the process of taking an input, generally called the plaintext, and convert it to a ciphertext (the output).</p></li>
<li><p>The ciphertext is not understandable if you do not have the key.</p></li>
<li><p>To get the plaintext from the ciphertext, you need to be authorized.</p></li>
<li><p>To get authorized, you need to have the key.</p></li>
<li><p>The key can be symmetric or asymmetric</p></li>
</ul></li>
</ul>
<div id="the-go.sum-file" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> The go.sum file <a href="#the-go.sum-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The go.sum file will contain cryptographic hashes of the module <strong>direct</strong> and <strong>indirect</strong> dependencies.<a href="#fn7" class="footnote-ref" id="fnref7" role="doc-noteref"><sup>7</sup></a></p>
<div id="a-sample-go.sum" class="anchor"></div>
<h2 data-number="16.1"><span class="header-section-number">16.1</span> A sample go.sum <a href="#a-sample-go.sum"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s first generate the go.mod file for the project myApp. In the terminal, we type :</p>
<pre v-highlightjs><code class="go" v-pre >$ cd myApp
$ go mod init</code></pre>
<p>The go.mod file generated is :</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/loir402/myApp</code></pre>
<p>It’s empty! That’s because go mod init do not fill the go.mod file with the required dependencies. To avoid doing it manually, you can run the command</p>
<pre v-highlightjs><code class="go" v-pre >$ go install</code></pre>
<p>This will update the go.mod file and create the go.sum file also :</p>
<pre v-highlightjs><code class="go" v-pre >// myApp/go.mod
module gitlab.com/loir402/myApp

require (
    gitlab.com/loir402/bar v1.0.0
    gitlab.com/loir402/foo v1.0.0
)</code></pre>
<p>We have our two direct dependencies listed in the file: foo and bar. The tag that has been selected automatically is the most recent one: v1.0.0 for both dependencies.</p>
<p>Go install has also generated the following <strong>go.sum</strong> file :</p>
<pre v-highlightjs><code class="go" v-pre >gitlab.com/loir402/bar v1.0.0 h1:l8z9pDvQfdWLOG4HNaEPHdd1FMaceFfIUv7nucKDr/E=
gitlab.com/loir402/bar v1.0.0/go.mod h1:i/AbOUnjwb8HUqxgi4yndsuKTdcZ/ztfO7lLbu5P/2E=
gitlab.com/loir402/baz v1.0.0 h1:ptLfwX2qCoPihymPx027lWKNlbu/nwLDgLcfGybmC/c=
gitlab.com/loir402/baz v1.0.0/go.mod h1:uUDHCXWc4HmQdep9P0glAYFdIEcenfXwuHmBfAMaEgA=
gitlab.com/loir402/corge v1.0.0 h1:UrSyy1/ZAFz3280Blrrc37rx5TBLwNcJaXKhN358XO8=
gitlab.com/loir402/corge v1.0.0/go.mod h1:xitAqlOH/wLiaSvVxYYkgqaQApnaionLWyrUAj6l2h4=
gitlab.com/loir402/foo v1.0.0 h1:sIEfKULMonD3L9COiI2GyGN7SdzXLw0rbT5lcW60t84=
gitlab.com/loir402/foo v1.0.0/go.mod h1:+IP28RhAFM6FlBl5iSYCGAJoG5GtZpUH4Mteu0ekyDY=
gitlab.com/loir402/qux v1.0.0 h1:B1efJPpCgzevbS5THHliTj1owKfOi0Yo7tIaAm65n6w=
gitlab.com/loir402/qux v1.0.0/go.mod h1:QexiArTQZcXRpFC3LLuGhk82aJoknf1n6c4WxlTeWdg=</code></pre>
<div id="anatomy-of-the-go.sum-file" class="anchor"></div>
<h2 data-number="16.2"><span class="header-section-number">16.2</span> Anatomy of the go.sum file : <a href="#anatomy-of-the-go.sum-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The go.sum file list all the project’s dependencies, the direct ones and the indirect ones. One dependency = two lines in this file. Let’s focus on the package foo :</p>
<pre v-highlightjs><code class="go" v-pre >gitlab.com/loir402/foo v1.0.0 h1:sIEfKULMonD3L9COiI2GyGN7SdzXLw0rbT5lcW60t84=
gitlab.com/loir402/foo v1.0.0/go.mod h1:+IP28RhAFM6FlBl5iSYCGAJoG5GtZpUH4Mteu0ekyDY=</code></pre>
<p>The two lines have the following anatomy :</p>
<figure>
<b-img :src="require('@/assets/images/go_sum.png')" alt="Go sum anatomy[fig:Go-sum-anatomy]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Go sum anatomy<span id="fig:Go-sum-anatomy" label="fig:Go-sum-anatomy">[fig:Go-sum-anatomy]</span></figcaption>
</figure>
<p>Go.sum will record the checksum of the module version used, but also the checksum of the go.mod file of the module; that’s why we have two lines per module.</p>
<p>The hashing algorithm used is <strong>SHA256</strong>.</p>
<ul>
<li><p>The first checksum is the hash of all the files of the module.</p></li>
<li><p>The second checksum is the hash of the go.mod file of the module.</p></li>
</ul>
<p>The hash is then converted to base64. The h1 string is fixed. It means that the <span v-highlightjs><code class="go" v-pre style="display: inline">Hash1</code></span> function inside of the go library is used<a href="#fn8" class="footnote-ref" id="fnref8" role="doc-noteref"><sup>8</sup></a></p>
<ul>
<li>The checksum is here to ensure that the downloaded versions of dependant modules are <strong>the same than</strong> the first download.</li>
</ul>
<div id="example" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Example <a href="#example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To understand better how things work, I have created six projects hosted on GitLab :</p>
<ul>
<li><p>https://gitlab.com/loir402/myApp</p></li>
<li><p>https://gitlab.com/loir402/foo</p></li>
<li><p>https://gitlab.com/loir402/bar</p></li>
<li><p>https://gitlab.com/loir402/baz</p></li>
<li><p>https://gitlab.com/loir402/qux</p></li>
<li><p>https://gitlab.com/loir402/corge</p></li>
</ul>
<div id="project-setup" class="anchor"></div>
<h2 data-number="17.1"><span class="header-section-number">17.1</span> Project Setup <a href="#project-setup"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The first project myApp is our main project that has two direct dependencies : foo and bar. The other projects are indirect dependencies of myApp.</p>
<p>Here is the code of myApp :</p>
<pre v-highlightjs><code class="go" v-pre >// modules/example/main.go
package main

import (
    &quot;fmt&quot;

    &quot;gitlab.com/loir402/bar&quot;
    &quot;gitlab.com/loir402/foo&quot;
)

func main() {
    fmt.Println(foo.Foo())
    fmt.Println(bar.Bar())
}</code></pre>
<p>In myApp we use the API of foo and bar. The package foo has no dependencies. Here is its code :</p>
<pre v-highlightjs><code class="go" v-pre >// foo/foo.go
package foo

func Foo() string {
    return &quot;Foo&quot;
}</code></pre>
<p>The package bar has two direct dependencies: baz and qux :</p>
<pre v-highlightjs><code class="go" v-pre >// bar/bar.go
package bar

import (
    &quot;fmt&quot;

    &quot;gitlab.com/loir402/baz&quot;
    &quot;gitlab.com/loir402/qux&quot;
)

func Bar() string {
    return fmt.Sprintf(&quot;Bar %s %s&quot;, baz.Baz(), qux.Qux())
}</code></pre>
<p>And here is the package qux :</p>
<pre v-highlightjs><code class="go" v-pre >// qux/qux.go
package qux

import (
    &quot;fmt&quot;

    &quot;gitlab.com/loir402/corge&quot;
)

func Qux() string {
    return fmt.Sprintf(&quot;Qux %s&quot;, corge.Corge())
}</code></pre>
<p>The package baz :</p>
<pre v-highlightjs><code class="go" v-pre >// baz/baz.go
package baz

import (
    &quot;fmt&quot;

    &quot;gitlab.com/loir402/corge&quot;
)

func Baz() string {
    return fmt.Sprintf(&quot;Baz %s&quot;, corge.Corge())
}</code></pre>
<p>And finally our last package corge (which is a dependency of baz and qux) :</p>
<pre v-highlightjs><code class="go" v-pre >// corge/corge.go
package corge

func Corge() string {
    return &quot;Corge&quot;
}</code></pre>
<p>Note that this last one has no dependencies.</p>
<p>I have created for each one of those packages a version <strong>v1.0.0</strong> on GitLab.</p>
<div id="upgrade-all-dependencies-to-the-latest-version" class="anchor"></div>
<h2 data-number="17.2"><span class="header-section-number">17.2</span> Upgrade all dependencies to the latest version <a href="#upgrade-all-dependencies-to-the-latest-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To update all the dependencies of your project to the latest version (<strong>minor versions and patches</strong>) you can run the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get -u ./...</code></pre>
<p>Let’s test it!</p>
<p>We will create a patch for the corge module. If you remember the previous section(about semantic versioning), a patch does not modify the module’s API (ie. everything that is exported by the module) :</p>
<pre v-highlightjs><code class="go" v-pre >// corge/corge.go
package corge

func Corge() string {
    return fmt.Sprintf(&quot;Corge&quot;)
}</code></pre>
<p>The signature is the same, but we just add a call to <span v-highlightjs><code class="go" v-pre style="display: inline">fmt.Sprintf</code></span>.</p>
<p>On GitLab, I created a new tag to materialize that a new version is out! The tag is v1.0.1 (the last digit was incremented).</p>
<p>Then I will run the command <span v-highlightjs><code class="go" v-pre style="display: inline">go get -u</code></span> inside the myApp folder. <strong>myApp</strong> has <strong>corge</strong> as an indirect dependency :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get -u ./...
go: finding gitlab.com/loir402/corge v1.0.1
go: downloading gitlab.com/loir402/corge v1.0.1</code></pre>
<p>You see that Go has detected that we have a new patch for corge, and he has downloaded it. The go.sum file is now :</p>
<pre v-highlightjs><code class="go" v-pre >//...
gitlab.com/loir402/corge v1.0.0 h1:UrSyy1/ZAFz3280Blrrc37rx5TBLwNcJaXKhN358XO8=
gitlab.com/loir402/corge v1.0.0/go.mod h1:xitAqlOH/wLiaSvVxYYkgqaQApnaionLWyrUAj6l2h4=
gitlab.com/loir402/corge v1.0.1 h1:F1IcYLNkWk/NiFtvOlFrgii2ixrTWg89QarFKWXPRrs=
gitlab.com/loir402/corge v1.0.1/go.mod h1:xitAqlOH/wLiaSvVxYYkgqaQApnaionLWyrUAj6l2h4=
//...</code></pre>
<p>and the go.mod file is :</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/loir402/myApp

require (
    gitlab.com/loir402/bar v1.0.0
    gitlab.com/loir402/corge v1.0.1 // indirect
    gitlab.com/loir402/foo v1.0.0
)</code></pre>
<p>Let’s note that :</p>
<ul>
<li><p>The previous version (v1.0.0) of corge is still into the go.sum file.</p></li>
<li><p>One line has been added to the go.mod file : “gitlab.com/loir402/corge v1.0.1”</p></li>
</ul>
<p>The go.sum file keeps the old version for safety purpose because you might want to downgrade the dependency that you just upgraded (because of a bug for instance). In that case, you want to be sure to roll back to the same version that worked before the upgrade.</p>
<div id="upgrade-a-dependency-to-the-latest-version" class="anchor"></div>
<h2 data-number="17.3"><span class="header-section-number">17.3</span> Upgrade a dependency to the latest version <a href="#upgrade-a-dependency-to-the-latest-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If you do not want to update every dependency, you can target a specific one with the go get command.</p>
<p>For instance, I have updated the code source of foo, and I have released a new version v1.0.1 (patch) :</p>
<pre v-highlightjs><code class="go" v-pre >// foo/foo.go
// v1.0.1
package foo

func Foo() string {
    return fmt.Sprintf(&quot;Foo&quot;)
}</code></pre>
<p>Then we can run the following command into the terminal (inside the myApp directory) to update only “foo” to the latest version :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get gitlab.com/loir402/foo
go: finding gitlab.com/loir402/foo v1.0.1
go: downloading gitlab.com/loir402/foo v1.0.1</code></pre>
<p>The go.mod file has been modified; foo is now required with the version v1.0.1 :</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/loir402/myApp

require (
    gitlab.com/loir402/bar v1.0.0
    gitlab.com/loir402/corge v1.0.1 // indirect
    gitlab.com/loir402/foo v1.0.1
)</code></pre>
<p>And the go.sum :</p>
<pre v-highlightjs><code class="go" v-pre >gitlab.com/loir402/foo v1.0.0 h1:sIEfKULMonD3L9COiI2GyGN7SdzXLw0rbT5lcW60t84=
gitlab.com/loir402/foo v1.0.0/go.mod h1:+IP28RhAFM6FlBl5iSYCGAJoG5GtZpUH4Mteu0ekyDY=
gitlab.com/loir402/foo v1.0.1 h1:6Dcvy69SCXzrGshVRDZzswqiA5Qm0n6Wt5VLOFtYF5o=
gitlab.com/loir402/foo v1.0.1/go.mod h1:+IP28RhAFM6FlBl5iSYCGAJoG5GtZpUH4Mteu0ekyDY=</code></pre>
<p>The old version is still here (to downgrade safely), and the new version has been added.</p>
<div id="upgrade-a-dependency-to-a-specific-version" class="anchor"></div>
<h2 data-number="17.4"><span class="header-section-number">17.4</span> Upgrade a dependency to a specific version <a href="#upgrade-a-dependency-to-a-specific-version"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To target a specific version, you can also use the go get command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get module_path@X</code></pre>
<p>Where X can be :</p>
<ul>
<li><p>A commit hash</p>
<ul>
<li>Ex : b822ebd</li>
</ul></li>
<li><p>A version</p>
<ul>
<li>v1.0.3</li>
</ul></li>
</ul>
<p>Go will fetch the requested revision of the module and install it locally. Let’s take an example. I have made an evolution to the bar module :</p>
<pre v-highlightjs><code class="go" v-pre >// bar/bar.go
package bar

import (
    &quot;fmt&quot;

    &quot;gitlab.com/loir402/baz&quot;
    &quot;gitlab.com/loir402/qux&quot;
)

func Bar() string {
    return fmt.Sprintf(&quot;Bar %s %s&quot;, baz.Baz(), qux.Qux())
}

func Bar2() string {
    return fmt.Sprintf(&quot;Bar2 %s %s&quot;, baz.Baz(), qux.Qux())
}</code></pre>
<p>I have added to the public API another exposed function Bar2. I have created a new minor version: v1.1.0.</p>
<p>Let’s update myApp to make it use this specific version :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get gitlab.com/loir402/bar@v1.1.0
go: finding gitlab.com/loir402/bar v1.1.0
go: downloading gitlab.com/loir402/bar v1.1.0</code></pre>
<p>Let’s check what’s changed into the go.mod :</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/loir402/myApp

require (
    gitlab.com/loir402/bar v1.1.0
    gitlab.com/loir402/corge v1.0.1 // indirect
    gitlab.com/loir402/foo v1.0.1
)</code></pre>
<p>The version of <strong>bar</strong> has been updated to v1.1.0 into the go.mod. Let’s check what’s changed into the go.sum file :</p>
<pre v-highlightjs><code class="go" v-pre >gitlab.com/loir402/bar v1.0.0 h1:l8z9pDvQfdWLOG4HNaEPHdd1FMaceFfIUv7nucKDr/E=
gitlab.com/loir402/bar v1.0.0/go.mod h1:i/AbOUnjwb8HUqxgi4yndsuKTdcZ/ztfO7lLbu5P/2E=
gitlab.com/loir402/bar v1.1.0 h1:VntceKGOvGEiCGeyyaik5NwU+4APgyS86IZ5/hm6uEc=
gitlab.com/loir402/bar v1.1.0/go.mod h1:i/AbOUnjwb8HUqxgi4yndsuKTdcZ/ztfO7lLbu5P/2E=</code></pre>
<p>The new version has been added to the list (the old version stays in the list)</p>
<div id="downgrade-a-dependency" class="anchor"></div>
<h2 data-number="17.5"><span class="header-section-number">17.5</span> Downgrade a dependency <a href="#downgrade-a-dependency"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In some cases, a new version that has been published is not completely stable and occurs bugs in your application. In that case, you might want to downgrade it to the previous working version.</p>
<p>We will simply run the same command as upgrading :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get gitlab.com/loir402/bar@v1.0.0</code></pre>
<p>This will rollback the local version of bar to v1.0.0.</p>
<div id="cleanup-task-before-a-release" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Cleanup task before a release <a href="#cleanup-task-before-a-release"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Before releasing your app, you want your <strong>go.mod</strong> and <strong>go.sum</strong> file to reflect exactly what you use. For the example, we will use the module myApp. We will make it use a new dependency: grault. We will add it to the project then remove it. The objective is to see what happens to our go.sum and go.mod file.</p>
<p>The source code of grault is quite the same as the other fake dependencies of our test. It exposes only a method Grault that send back the string “Grault”. Nothing new here :</p>
<pre v-highlightjs><code class="go" v-pre >// grault/grault.go
// v1.0.0
package grault

import &quot;fmt&quot;

func Grault() string {
    return fmt.Sprintf(&quot;Grault&quot;)
}</code></pre>
<p>Let’s use it into myApp :</p>
<pre v-highlightjs><code class="go" v-pre >// myApp/main.go
package main

import (
    //...
    &quot;gitlab.com/loir402/grault&quot;
)

func main() {
    //...
    fmt.Println(grault.Grault())
}</code></pre>
<p>When we run <strong>go install</strong> a new line has been added to the go.mod file and the go.sum file. Here is the go.mod :</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/loir402/myApp

require (
    gitlab.com/loir402/bar v1.0.0
    gitlab.com/loir402/corge v1.0.1 // indirect
    gitlab.com/loir402/foo v1.0.1
    gitlab.com/loir402/grault v1.0.0
)</code></pre>
<p>Now let’s remove the usage of grault and launch again <strong>go install.</strong> The go.mod file and the go.sum stay the same, but we no longer use grault. To clean up that we have to launch :</p>
<pre v-highlightjs><code class="go" v-pre >$ go mod tidy -v

unused gitlab.com/loir402/grault</code></pre>
<p>This will:</p>
<ul>
<li><p>remove the mentions to grault in the go.mod and go.sum file</p></li>
<li><p>remove unused versions of dependencies inside the go.sum file</p></li>
</ul>
<p>When you delete in go.sum the lines that refer to older versions of your dependencies, you might not get the same version of the module you used (when downgrading this module). When you downgrade, you want to be sure that the old version is the same as it was before. The cryptographic checksum stored into the go.sum file is here for that specific purpose.</p>
<div id="where-are-downloaded-versions-of-modules-stored" class="anchor"></div>
<h1 data-number="19"><span class="header-section-number">19</span> Where are downloaded versions of modules stored? <a href="#where-are-downloaded-versions-of-modules-stored"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The different versions of the dependencies that have been downloaded by the go tool have been placed into the <strong>$GOPATH/pkg/mod</strong> directory. In the next listing, I have represented the tree of the folder mod :</p>
<figure>
<b-img :src="require('@/assets/images/tree_cache_loir402.png')" alt="Tree view of the cache folder"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Tree view of the cache folder</figcaption>
</figure>
<div id="is-go.sum-a-lock-file" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="20"><span class="header-section-number">20</span> Is go.sum a lock file? <a href="#is-go.sum-a-lock-file"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>For gophers that have worked with other languages and their related dependency management system, the go.sum looks like a lock file. A lock file lists the dependencies that your project use along with a specific revision (a tag or a commit sha1).</p>
<p>Here is an example of a lock file for the Nodejs language (with versioning tool npm) :</p>
<pre v-highlightjs><code class="go" v-pre >{
  &quot;name&quot;: &quot;nodeLock&quot;,
  &quot;version&quot;: &quot;1.0.0&quot;,
  &quot;lockfileVersion&quot;: 1,
  &quot;requires&quot;: true,
  &quot;dependencies&quot;: {
    &quot;moment&quot;: {
      &quot;version&quot;: &quot;2.22.2&quot;,
      &quot;resolved&quot;: &quot;https://registry.npmjs.org/moment/-/moment-2.22.2.tgz&quot;,
      &quot;integrity&quot;: &quot;sha1-PCV/mDn8DpP/UxSWMiOeuQeD/2Y=&quot;
    }
  }
}</code></pre>
<p>In this project, I used only one dependency called “moment” in version <strong>v2.22.2.</strong></p>
<p>Here is another example of a lock file for a PHP project (using composer as dependency manager) :</p>
<pre v-highlightjs><code class="go" v-pre >{
    //...
    &quot;packages&quot;: [
        {
            &quot;name&quot;: &quot;psr/log&quot;,
            &quot;version&quot;: &quot;1.0.2&quot;,
            &quot;source&quot;: {
                &quot;type&quot;: &quot;git&quot;,
                &quot;url&quot;: &quot;https://github.com/php-fig/log.git&quot;,
                &quot;reference&quot;: &quot;4ebe3a8bf773a19edfe0a84b6585ba3d401b724d&quot;
            },
            &quot;dist&quot;: {
                &quot;type&quot;: &quot;zip&quot;,
                &quot;url&quot;: &quot;https://api.github.com/repos/php-fig/log/zipball/4ebe3a8bf773a19edfe0a84b6585ba3d401b724d&quot;,
                &quot;reference&quot;: &quot;4ebe3a8bf773a19edfe0a84b6585ba3d401b724d&quot;,
                &quot;shasum&quot;: &quot;&quot;
            },
            &quot;require&quot;: {
                &quot;php&quot;: &quot;&gt;=5.3.0&quot;
            },
    //....
}</code></pre>
<p>How is it different from the go.sum file?</p>
<ul>
<li><p>The go.sum file stores the versions and the <strong>cryptographic</strong> sum of direct and indirect modules used by your application</p>
<ul>
<li><p>The aim of the go.sum file is to ensure that modules will not be <strong>altered</strong> at the next download.</p></li>
<li><p>Whereas the goal of a lock file is to allow reproducible builds.</p></li>
</ul></li>
<li><p>Go use a <strong>deterministic</strong> approach to version selection.</p>
<ul>
<li><p>The build list will remain stable with time (if revisions used are not deleted by maintainer)</p></li>
<li><p>It means that the build list generated in January will not be different from the one generated in December.</p></li>
<li><p>Thus the introduction of a lock file is not necessary.</p></li>
</ul></li>
<li><p>Dependency management systems that have introduced lock files generally don’t have such a deterministic approach</p></li>
<li><p>The lock file is needed to ensure that the builds of the application are reproducible by listing all the dependencies used and at which version.</p></li>
</ul>
<div id="go.sum-go.mod-commit-them-or-not" class="anchor"></div>
<h1 data-number="21"><span class="header-section-number">21</span> Go.sum &amp; go.mod : commit them or not ? <a href="#go.sum-go.mod-commit-them-or-not"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You should <strong>commit</strong> your go.mod and go.sum in your VCS (for instance git), because :</p>
<ul>
<li><p>Others need the go.mod file to construct their build list.</p></li>
<li><p>Others will use the go.sum to ensure that the module downloaded have not been altered.</p></li>
</ul>
<div id="other-commands-to-know" class="anchor"></div>
<h1 data-number="22"><span class="header-section-number">22</span> Other commands to know <a href="#other-commands-to-know"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The go mod command has other interesting commands that you should know. This section will focus only on commands that I find interesting.</p>
<div id="go-mod-graph" class="anchor"></div>
<h2 data-number="22.1"><span class="header-section-number">22.1</span> go mod graph <a href="#go-mod-graph"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>go mod graph will output a dependency graph of your module to the standard output.</p>
<p>For instance, here is the graph of myApp :</p>
<pre v-highlightjs><code class="go" v-pre >gitlab.com/loir402/myApp gitlab.com/loir402/bar@v1.0.0
gitlab.com/loir402/myApp gitlab.com/loir402/corge@v1.0.1
gitlab.com/loir402/myApp gitlab.com/loir402/foo@v1.0.1
gitlab.com/loir402/bar@v1.0.0 gitlab.com/loir402/baz@v1.0.0
gitlab.com/loir402/bar@v1.0.0 gitlab.com/loir402/qux@v1.0.0
gitlab.com/loir402/qux@v1.0.0 gitlab.com/loir402/corge@v1.0.0
gitlab.com/loir402/baz@v1.0.0 gitlab.com/loir402/corge@v1.0.0</code></pre>
<p>It’s not very visual, but it gives interesting information about myApp application. In the figure <a href="#fig:Go-mod-graph" data-reference-type="ref" data-reference="fig:Go-mod-graph">4</a> I have represented it using arrows and circles. Each line of the output represents an edge between two packages. In this example, we have seven edges and six modules (foo, bar, baz, qux, corge, and myApp). Corge appears two times because myApp depends on corge v1.0.1, whereas qux and baz depend on corge v1.0.0.</p>
<figure>
<b-img :src="require('@/assets/images/go_mod_graph.png')" alt="Go mod graph[fig:Go-mod-graph]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Go mod graph<span id="fig:Go-mod-graph" label="fig:Go-mod-graph">[fig:Go-mod-graph]</span></figcaption>
</figure>
<div id="go-mod-vendor" class="anchor"></div>
<h2 data-number="22.2"><span class="header-section-number">22.2</span> go mod vendor <a href="#go-mod-vendor"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The command <strong>go mod vendor</strong> will :</p>
<ul>
<li>create a <strong>vendor</strong> folder with all the sources of your dependencies.</li>
</ul>
<p>Here is a tree view of the vendor folder of myApp :</p>
<figure>
<b-img :src="require('@/assets/images/tree_cendor_go_module.png')" alt="Tree view of vendor directory"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Tree view of vendor directory</figcaption>
</figure>
<ul>
<li>The vendor folder also contains a file : <strong>modules.txt</strong> that lists the dependencies path along with the version number.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre ># gitlab.com/loir402/bar v1.0.0
gitlab.com/loir402/bar
# gitlab.com/loir402/baz v1.0.0
gitlab.com/loir402/baz
# gitlab.com/loir402/corge v1.0.1
gitlab.com/loir402/corge
# gitlab.com/loir402/foo v1.0.1
gitlab.com/loir402/foo
# gitlab.com/loir402/qux v1.0.0
gitlab.com/loir402/qux</code></pre>
<div id="go-mod-verify" class="anchor"></div>
<h2 data-number="22.3"><span class="header-section-number">22.3</span> go mod verify <a href="#go-mod-verify"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>As the name indicates it go mod verify will check your locally stored dependencies. Go will check that your locally stored dependencies have not been changed. This check is very useful to ensure that you are using the correct version of your dependencies and not an altered version. Those alterations can cause builds to fail.</p>
<p>The command-line tool will check that for each module of the build list that the corresponding files downloaded located in <strong>pkg/mod/cache/download</strong> are not altered. Here is how the foder <strong>pkg/mod/cache/download is structured</strong></p>
<figure>
<b-img :src="require('@/assets/images/tree_view_chache_download.png')" alt="Tree view of cache download directory"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Tree view of cache download directory</figcaption>
</figure>
<p>You can see that in this directory, Go has stored each version that we used for development. For each version, we have four different files :</p>
<ul>
<li><p>VERSION.info</p></li>
<li><p>VERSION.mod</p></li>
<li><p>VERSION.ziphash</p></li>
<li><p>VERSION.zip</p></li>
</ul>
<p>The info file contains the data at which it has been downloaded along with the version number :</p>
<pre v-highlightjs><code class="go" v-pre >{&quot;Version&quot;:&quot;v1.0.0&quot;,&quot;Time&quot;:&quot;2018-11-03T19:36:07Z&quot;}</code></pre>
<p>The .mod file is the exact reproduction of the original .mod file of the module. The file .ziphash contains the hash of the .zip file.</p>
<p>I have tried to modify the zipped version of the module and then to run go mod verify. Here is the error message that is displayed by go :</p>
<pre v-highlightjs><code class="go" v-pre >gitlab.com/loir402/foo v1.0.1: zip has been modified (/Users/maximilienandile/go/pkg/mod/cache/download/gitlab.com/loir402/foo/@v/v1.0.1.zip)</code></pre>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="23"><span class="header-section-number">23</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="23.1"><span class="header-section-number">23.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>A minor version introduces breaking changes. True or False?</p></li>
<li><p>What is the command to update a module to its latest version?</p></li>
<li><p>What is the name of the set of algorithms used in Go to manage dependencies?</p></li>
<li><p>Write a sentence describing a Module with the following words: packages, source files, go.mod, go.sum.</p></li>
<li><p>What is the purpose of the go.mod file?</p></li>
<li><p>What is the purpose of the go.sum file?</p></li>
<li><p>What is the command to initialize a module?</p></li>
<li><p>What is the command to display the build list of a module?</p></li>
<li><p>When the major version 2 is released, the module path is not modified. True or False?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="23.2"><span class="header-section-number">23.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>A minor version introduces breaking changes. True or False?</p>
<ol type="1">
<li><p>False</p></li>
<li><p><strong>Major</strong> versions introduce breaking changes</p></li>
</ol></li>
<li><p>What is the command to update a module to its latest version?</p>
<pre v-highlightjs><code class="go" v-pre >$ go get -u path/of/the/module</code></pre></li>
<li><p>What is the name of the set of algorithms used in Go to manage dependencies?</p>
<ol type="1">
<li>MVS: Minimum Version Selection</li>
</ol></li>
<li><p>Create a sentence describing a Module with the following words: packages, go.mod, go.sum.</p>
<ol type="1">
<li>A module is a group of packages and two files, a go.mod &amp; a go.sum.</li>
</ol></li>
<li><p>What is the purpose of the go.mod file?</p>
<ol type="1">
<li><p>The go.mod file will define the <strong>module path</strong> of the current module</p></li>
<li><p>The go.mod file lists <strong>minimum versions</strong> of <strong>direct dependencies.</strong></p></li>
<li><p>It also lists the minimum versions of <strong>indirect</strong> dependencies</p>
<ol type="1">
<li>It happens when the developer upgraded or removed some dependencies manually</li>
</ol></li>
<li><p>Each dependency is identified by a <strong>module path</strong>.</p></li>
<li><p>It also gives the expected language version for the module</p></li>
</ol></li>
<li><p>What is the purpose of the go.sum file?</p>
<ol type="1">
<li>The go.sum file is to ensure that the source code of dependencies downloaded <strong>are the same</strong> as the one downloaded by the original developer.</li>
</ol></li>
<li><p>What is the command to initialize a module?</p>
<ol type="1">
<li>In your module directory :</li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ go mod init path/of/the/module</code></pre></li>
<li><p>What is the command to display the build list of a module?</p>
<ol type="1">
<li>In your module directory :</li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ go list -m all</code></pre></li>
<li><p>When the major version 2 is released, the module path is not modified. True or False?</p>
<ol type="1">
<li><p>False</p></li>
<li><p>Because a new major version introduces breaking changes, the module path should change (to respect the import compatibility rule)</p></li>
<li><p>The string “v2” should be added to the module path</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="24"><span class="header-section-number">24</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A Go module is a set of packages that are versioned together with a version control system (for instance, Git)</p></li>
<li><p>Go modules are identified by a module path.</p>
<ul>
<li>Module paths describe what the module does and where we can find it</li>
</ul></li>
<li><p>A version is identified by a tag that describes the version changes.</p></li>
<li><p>To describe what changes are added in a version, we usually use a versioning scheme which is a set of rules enforced by developers</p></li>
<li><p>Semantic Versioning is a versioning scheme that Go uses</p>
<ul>
<li><p>In this scheme, a version number is a string formatted this way =&gt; “vX.Y.Z” (v is optional)</p>
<ul>
<li><p>X, Y, Z are unsigned integers.</p></li>
<li><p>X is the major version number. When you increase this number, it means that you introduce breaking changes</p></li>
<li><p>Y is the minor number. This number should be increased when new non-breaking features are added.</p></li>
<li><p>Z is the patch number. This number is increased when a patch is created (a bug fix, for instance)</p></li>
</ul></li>
</ul></li>
<li><p>When a module publish a new major version greater or equal than 2, it should add a <strong>major version suffix</strong> to the module path</p>
<ul>
<li><strong>gitlab.com/loir402/bluesodium</strong> becomes <strong>gitlab.com/loir402/bluesodium/v2</strong></li>
</ul></li>
<li><p>We can initialize a Go module in an existing project by executing the <span v-highlightjs><code class="go" v-pre style="display: inline">go mod init my/new/module/path</code></span> command.</p></li>
<li><p>To add a new direct dependency to a program, use the <strong>go get</strong> command:</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">$ go get my/new/module/to/add</code></span></p></li>
<li><p>To upgrade all your dependencies, use the <strong>go get</strong> command:</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">$ go get -u ./...</code></span></p></li>
<li><p>To upgrade one dependency, use the <strong>go get</strong> command:</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">$ go get -u the/module/path/you/want/to/upgrade</code></span></p></li>
<li><p>In the go.mod file, you can <strong>replace</strong> the code of a module by another one (stored on a code-sharing website or locally)</p>
<pre v-highlightjs><code class="go" v-pre >replace gitlab.com/loir402/corge =&gt; ./corgeforked</code></pre></li>
<li><p>You can also exclude a specific version from your builds.</p>
<pre v-highlightjs><code class="go" v-pre >exclude gitlab.com/loir402/bluesodium v2.0.1</code></pre></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://en.wikipedia.org/wiki/Software_versioning<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>https://semver.org/<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>Tom is one of the cofounders of Github<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>In this section, we will see how MVS works. It is a transcription of the excellent Russ Cox’s article <b-link class="citation" data-cites="minimal-version-cox" href="#minimal-version-cox" >[@minimal-version-cox]</b-link>.<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn5" role="doc-endnote"><p>Please note that the actual implementation of this algorithm is not as described. The actual implementation is based on graph traversal, which is more efficient than this approach. Take a look at the Russ Cox article to know more about that!<a href="#fnref5" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn6" role="doc-endnote"><p>Source: go help get<a href="#fnref6" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn7" role="doc-endnote"><p>https://golang.org/ref/mod section go.sum<a href="#fnref7" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn8" role="doc-endnote"><p>if you are curious, here is the source code that generated the checksum: <a href="https://github.com/golang/go/blob/master/src/cmd/go/internal/dirhash/hash.go" class="uri">https://github.com/golang/go/blob/master/src/cmd/go/internal/dirhash/hash.go</a><a href="#fnref8" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap17GoModules"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap16Interfaces'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Interfaces</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap18GoModuleProxies'}">
									<p><u><small>Next</small></u></p>
									<p><small>Go Module Proxies</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Go modules - Practical Go Lessons"
const description = "Modules have been introduced with version 1.11 of Go. Gophers can split their developments into separate code units that can be reused across other projects."

export default {
  name: "Chap17GoModules",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
